import React from 'react';
import { graphql } from 'gatsby';
import CardContainer from '../components/styled/CardContainer';
import Card from '../components/styled/Card';
import Carousel from '../components/styled/Carousel';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/styled/Hero';
import HomeLinks from '../components/styled/HomeLinks';

const IndexPage = ({ data }) => {
  const { testimonials, heroes, cards, homeLinks } = data;

  return (
    <Layout>
      <SEO title="Home" />
      <Hero data={heroes} />
      <CardContainer>
        {cards.edges.map(({ node }, index) => (
          <Card
            key={index}
            content={node.text}
            img={node.image.fluid.src}
            title={node.title}
            link={node.link}
            icon={node.icon}
          />
        ))}
      </CardContainer>
      <Carousel link="/" data={testimonials} />
      <HomeLinks data={homeLinks} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    testimonials: allContentfulTestimonial {
      edges {
        node {
          title
          testimonyName
          content
          testimonyImage {
            fluid(cropFocus: CENTER, resizingBehavior: FILL, quality: 100) {
              src
            }
          }
        }
      }
    }
    heroes: allContentfulHero {
      edges {
        node {
          title
          titleEmphasis
          text
          link
          image {
            fluid(cropFocus: CENTER, maxHeight: 406, quality: 100) {
              src
            }
          }
        }
      }
    }
    cards: allContentfulCard {
      edges {
        node {
          title
          text
          link
          icon
          image {
            fluid(cropFocus: CENTER, resizingBehavior: FILL, quality: 100) {
              src
            }
          }
        }
      }
    }
    homeLinks: allContentfulHomeLink {
      edges {
        node {
          title
          titleEmphasis
          text
          links {
            slug
            title
            page: header {
              slug
            }
          }
        }
      }
    }
  }
`;
