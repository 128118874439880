import styled from 'styled-components';
import React from 'react';
import Icon from './Icon';
import { Link } from 'gatsby';
import Flex from './base/Flex';
import Typography from './base/Typography';
import Box from './base/Box';

const Image = styled.img`
  width: 100%;
  min-height: 230px;
  max-height: 300px;
  object-fit: cover;
  object-position: 50% 50%;
`;

const TypographyWithHover = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;

const Card = ({ content, img, title, link, icon }) => (
  <Box
    as={Flex}
    boxShadow={[0, 0, 0, 'none']}
    height="full"
    flexDirection="column"
  >
    <Image src={img} alt={title} />
    <Flex flex="1" flexDirection="column" px={[3, 3, 3, 0]} pb={3}>
      <Typography color="primary" as="h3" fontWeight="bold">
        {title}
      </Typography>
      <Flex flex="1" mb={2}>
        {content}
      </Flex>
      <TypographyWithHover
        fontSize="18px"
        fontWeight="semibold"
        as={Link}
        color="primary"
        to={`/${link}`}
      >
        {icon && <Icon mr={2} name={icon} />}
        {icon === 'play' ? 'Bekijk video' : 'Lees meer'}
      </TypographyWithHover>
    </Flex>
  </Box>
);

export default Card;
