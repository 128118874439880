import React from 'react';
import Flex from './base/Flex';
import Typography from './base/Typography';
import Box from './base/Box';
import styled from 'styled-components';
import { StyledAnchor } from './base/A';

const Img = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const ImageContainer = styled(Box)`
  max-height: 406px;
  height: 40vh;
`;

const StyledAnchorWithHover = styled(StyledAnchor)`
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Hero = ({ data }) => {
  const { title, titleEmphasis, text, link, image } = data.edges[0].node;

  return (
    <Flex
      justifyContent="space-between"
      pb="68px"
      flexDirection={['column-reverse', 'column-reverse', 'row']}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Typography
          fontWeight="bold"
          my="0"
          color="primary"
          as="h1"
          variant="h1"
          lineHeight="1.3"
        >
          {title} <br />
          <Typography color="secondary" as="span">
            {titleEmphasis}
          </Typography>
        </Typography>
        <Typography pr={[2, 3, 4, 6]} textAlign="justify">
          {text}
        </Typography>
        {link && (
          <StyledAnchorWithHover
            fontWeight="semibold"
            withBorder
            to={`/${link}`}
          >
            Lees meer over Mentorschap
          </StyledAnchorWithHover>
        )}
      </Flex>
      <ImageContainer>
        <Img src={image.fluid.src} />
      </ImageContainer>
    </Flex>
  );
};

export default Hero;
