import React from 'react';
import Flex from './base/Flex';
import Box from './base/Box';

const CardContainer = ({ children }) => {
  return (
    <Flex flexWrap="wrap" mx={-3} justifyContent="space-between">
      {children.map((card, i) => (
        <Box
          key={i}
          px={3}
          mb={[4, null, null, 0]}
          width={[1, null, null, 1 / 3]}
        >
          {card}
        </Box>
      ))}
    </Flex>
  );
};
export default CardContainer;
