import React from 'react';
import styled from 'styled-components';
import Flex from './base/Flex';
import Box from './base/Box';
import Typography from './base/Typography';
import A from './base/A';
import Icon from './Icon';

const Title = styled(Typography).attrs(props => ({
  variant: 'h2',
  mt: '0',
  width: '430px',
  display: 'inline',
  fontWeight: 'semibold',
  color: props.color,
}))``;

const AWitHover = styled(A)`
  &:hover {
    color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;

const HomeLinks = ({ data }) => {
  const homeLinks = data.edges[0].node;

  const mapLinksToRender = links => {
    return links.map(link => {
      return (
        <Flex
          key={link.title}
          width={['full', null, null, '45%', '380px']}
          height="60px"
          bg="background"
          mb="3"
          boxShadow="1"
          borderRadius="6px"
          p="18px"
          alignItems="center"
        >
          <Icon name="chevron_left" size="20px" color="primary" mr="3" />
          <AWitHover
            to={`/${link.page[0].slug}/${link.slug}`}
            fontSize={4}
            color="primary"
            fontWeight="semibold"
          >
            {link.title}
          </AWitHover>
        </Flex>
      );
    });
  };

  return (
    <Flex
      bg={['secondaryOpaque', null, null, null, 'transparent']}
      position="relative"
      flexDirection="column"
      mb={['3', null, '4', null, '5']}
    >
      <Box
        bg={[null, null, null, null, 'secondaryOpaque']}
        width={['full', null, null, null, '83%']}
        height={[null, null, null, '200px', '330px']}
        py={['30px', null, null, null, '90px']}
        pl={[0, null, null, null, '130px']}
        textAlign={['center', null, null, null, 'left']}
      >
        <Title color="primary">{homeLinks.title} </Title>
        <Title color="secondary">{homeLinks.titleEmphasis}</Title>
        <Typography
          width={['90%', '400px', null, null, '310px']}
          textAlign="justify"
          fontSize={2}
          mx={['auto', null, null, null, 0]}
        >
          {homeLinks.text}
        </Typography>
      </Box>
      <Flex
        position={[null, null, null, null, 'absolute']}
        top={[null, null, null, null, '60px']}
        right={[null, null, null, null, '60px']}
        flexDirection={['column', null, null, 'row', 'column']}
        flexWrap={['nowrap', 'wrap']}
        justifyContent="space-between"
        width={['90%', null, null, null, 'initial']}
        mx="auto"
      >
        {mapLinksToRender(homeLinks.links)}
      </Flex>
    </Flex>
  );
};

export default HomeLinks;
