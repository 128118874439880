import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SlickSlider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Box from './base/Box';
import Flex from './base/Flex';
import PropTypes from 'prop-types';
import Button from './Button';
import Typography from './base/Typography';

const CarouselButton = styled(Button)`
  background-color: transparent;
  position: absolute;
  top: 0;
  height: 100%;
  width: 70px;
  justify-content: center;
  align-items: center;

  ${({ previous, next }) => (previous ? 'left: 0;' : next && 'right: 0;')};
  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.primary};
    background-color: rgba(0, 0, 0, 0.015);
  }
`;

const SliderImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin: auto;
`;

const Carousel = props => {
  const prevBtn = useRef(null);
  const nextBtn = useRef(null);

  useEffect(() => {
    prevBtn.current = document.querySelector('.slick-prev');
    nextBtn.current = document.querySelector('.slick-next');
  });

  const handleNext = () => nextBtn.current.click();
  const handlePrev = () => prevBtn.current.click();

  return (
    <Box
      position="relative"
      textAlign="center"
      my={5}
      p={3}
      bg="primaryLight"
      {...props}
    >
      <Typography as="h3" color="primary">
        {props.header}
      </Typography>

      <Box>
        <SlickSlider>
          {props.data.edges.map((item, index) => {
            const {
              node: {
                testimonyName,
                content,
                testimonyImage: {
                  fluid: { src },
                },
              },
            } = item;

            return (
              <Box px={[2, 5]} position="relative" key={index}>
                <Flex
                  borderRadius="100%"
                  size={['40vw', '25vw', '15vw']}
                  overflow="hidden"
                  mx="auto"
                >
                  <SliderImage src={src} alt={testimonyName} />
                </Flex>
                <Typography>{content}</Typography>
                <Typography fontWeight="bold">{testimonyName}</Typography>
              </Box>
            );
          })}
        </SlickSlider>
      </Box>
      <CarouselButton
        display={['none', 'flex']}
        onClick={handlePrev}
        previous
        icon="chevron_left"
      />
      <CarouselButton
        display={['none', 'flex']}
        onClick={handleNext}
        next
        icon="chevron_right"
      />
    </Box>
  );
};

Carousel.defaultProps = {
  link: '/',
  header: 'Wat mensen van mentorschap vinden?',
};

Carousel.propTypes = {
  data: PropTypes.object.isRequired,
  link: PropTypes.string,
  header: PropTypes.string.isRequired,
};

export default Carousel;
